%arrow {
    background-image: url(assets/images/sprite.png);
    background-position: -167px 0px;
    width: 117px;
    height: 162px;
}
%file {
    background-image: url(assets/images/sprite.png);
    background-position: 0px 0px;
    width: 147px;
    height: 168px;
}
