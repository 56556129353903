@mixin clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin make-container($gutter: $grid-gutter-width) {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-left:  ($gutter / 2);
  padding-right: ($gutter / 2);
  @include clearfix();
}

@mixin make-container-max-widths($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {
  @each $breakpoint, $container-max-width in $max-widths {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      max-width: $container-max-width;
    }
  }
}

@mixin make-row($gutter: $grid-gutter-width) {
  @include clearfix();
  margin-left:  ($gutter / -2);
  margin-right: ($gutter / -2);
}

@function breakpoint-next($name, $breakpoints: $grid-breakpoints, $breakpoint-names: map-keys($breakpoints)) {
  $n: index($breakpoint-names, $name);
  @return if($n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}

@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min != 0, $min, null);
}

@function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
  $next: breakpoint-next($name, $breakpoints);
  @return if($next, breakpoint-min($next, $breakpoints) - 1px, null);
}

@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin make-grid-columns($columns: $grid-columns, $gutter: $grid-gutter-width, $breakpoints: $grid-breakpoints) {

  %grid-column {
    position: relative;
    min-height: 1px;
    padding-right: ($gutter / 2);
    padding-left: ($gutter / 2);
  }

  $breakpoint-counter: 0;
  @each $breakpoint in map-keys($breakpoints) {
    $breakpoint-counter: ($breakpoint-counter + 1);

    @for $i from 1 through $columns {
      .#{$breakpoint}-#{$i} {
        @extend %grid-column;
      }
    }

    @include media-breakpoint-up($breakpoint, $breakpoints) {


      @for $i from 1 through $columns {
        .#{$breakpoint}-#{$i} {
          @include make-col($i, $columns);
        }
      }

      @each $modifier in (pull, push) {
        @for $i from 0 through $columns {
          .#{$modifier}-#{$breakpoint}-#{$i} {
            @include make-col-modifier($modifier, $i, $columns)
          }
        }
      }

      @for $i from 0 through ($columns - 1) {
        @if $breakpoint-counter != 1 or $i != 0 {
          .offset-#{$breakpoint}-#{$i} {
            @include make-col-modifier(offset, $i, $columns)
          }
        }
      }
    }
  }
}

@mixin make-col-ready($gutter: $grid-gutter-width) {
  position: relative;
  min-height: 1px;
  padding-right: ($gutter / 2);
  padding-left:  ($gutter / 2);
}

@mixin make-col($size, $columns: $grid-columns) {
    float: left;
    width: percentage($size / $columns);
}

@mixin make-col-offset($size, $columns: $grid-columns) {
  margin-left: percentage($size / $columns);
}

@mixin make-col-push($size, $columns: $grid-columns) {
  left: if($size > 0, percentage($size / $columns), auto);
}

@mixin make-col-pull($size, $columns: $grid-columns) {
  right: if($size > 0, percentage($size / $columns), auto);
}

@mixin make-col-modifier($type, $size, $columns) {

  @if $type == push {
    @include make-col-push($size, $columns);
  } @else if $type == pull {
    @include make-col-pull($size, $columns);
  } @else if $type == offset {
    @include make-col-offset($size, $columns);
  }
}