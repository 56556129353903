html {
	-webkit-tap-highlight-color: transparent;
	-webkit-text-size-adjust: 100%;
	    -ms-text-size-adjust: 100%;
        -ms-overflow-style: scrollbar;
}
html,
body {
	font-family: sans-serif;

	margin: 0;
	padding: 0;
}
*,
*:before,
*:after {
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
	display: block;
}
audio,
canvas,
progress,
video {
	display: inline-block;

	vertical-align: baseline;
}
audio:not([controls]) {
	display: none;

	height: 0;
}
a {
	background-color: transparent;
}
a:active,
a:hover {
	outline: 0;
}
img {
	vertical-align: middle;

	border: 0;
}
svg:not(:root) {
	overflow: hidden;
}
hr {
	-webkit-box-sizing: content-box;
	   -moz-box-sizing: content-box;
	        box-sizing: content-box;
	height: 0;
}
pre {
	overflow: auto;
}
code,
kbd,
pre,
samp {
	font-family: monospace,monospace;
	font-size: 1em;
}
button,
input,
optgroup,
select,
textarea {
	font: inherit;

	margin: 0;

	color: inherit;
}
:focus,
input,
textarea,
input:active,
textarea:active,
a {
	outline: none;
}
button {
	overflow: visible;
}
button,
select {
	text-transform: none;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
	cursor: pointer;

	-webkit-appearance: button;
}
button[disabled],
html input[disabled] {
	cursor: default;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
	padding: 0;

	border: 0;
}
input {
	line-height: normal;
}
input[type="checkbox"],
input[type="radio"] {
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	padding: 0;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	height: auto;
}
input[type="search"] {
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;

	-webkit-appearance: textfield;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
}
legend {
	padding: 0;

	border: 0;
}
textarea {
	overflow: auto;
}

@-moz-viewport {
	width: device-width;
}

@-ms-viewport {
	width: device-width;
}

@-webkit-viewport {
	width: device-width;
}

@viewport {
	width: device-width;
}
input,
button,
select,
textarea {
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}
address {
    font-style: normal;
    line-height: inherit;
}
figure {
	margin: 0;
}
.img-responsive {
	display: block;

	max-width: 100%;
	height: auto;
}
.video-responsive {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow:hidden;

    iframe,
    object,
    embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}


h1,
h2,
h3,
h4,
h5,
h6,
p {
	font-family: inherit;

	margin: 0;
	padding: 0;

	color: inherit;
}

ul,
ol {
	margin: 0;

	list-style: none;
}
ul ul,
ol ul,
ul ol,
ol ol {
	margin: 0;
}
blockquote {
    margin: 0 0 15px;
}
[role="button"] {
    cursor: pointer;
}
a,
area,
button,
[role="button"],
input,
label,
select,
summary,
textarea {
  -ms-touch-action: manipulation;
      touch-action: manipulation;
}
input[type="radio"],
input[type="checkbox"] {
	line-height: normal;
}
input[type="file"] {
	display: block;
}
input[type="range"] {
	display: block;

	width: 100%;
}
select[multiple],
select[size] {
	height: auto;
}
input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
	outline: thin dotted;
	outline: 5px auto -webkit-focus-ring-color;
	outline-offset: -2px;
}