//////////////////////////////////////////////
// HEADER
//////////////////////////////////////////////
.site-header {
    background-color: #FFF;
    min-height: 75px;

    &__logo {
        display: inline-block;
        line-height: 75px;

        img {
            width: auto;
            max-width: 100%;
            height: auto;
        }

    }

    &__menu {
        text-align: right;
        position: relative;

        .button-menu-open {
            width: 30px;
            height: 36px;
            border: 0;
            background-color: transparent;
            display: inline-block;
            padding: 0;
            margin-top: 20px;
            transition: all 0.3s ease-in-out;
            position: relative;

            .bar {
                width: 100%;
                height: 4px;
                background-color: $color_a;
                margin: 4px 0;
                display: block;
                transition: all 0.3s ease-in-out;
            }

            &.closed {

                .bar {
                    transform-origin: center center;
                    margin: 0;
                    position: absolute;
                    top: 50%;
                    left: 0;

                    &:nth-child(1) {
                        width: 0;
                    }

                    &:nth-child(2) {
                        transform: rotate(45deg);
                    }

                    &:nth-child(3) {
                        transform: rotate(-45deg);
                    }

                    &:nth-child(4) {
                        width: 0;
                    }

                }

            }

        }

        .menu {
            display: none;
            position: absolute;
            top: 75px;
            right: 0;
            overflow: hidden;
            transition: all 0.3s linear;
            background-color: #FFF;
            z-index: 999;
            width: 250px;

            &.open-menu {
                display: block;
            }

            ul {
                padding: 0;
                text-align: left;
                padding: 15px 0;
                width: 100%;

                li {
                    display: block;

                    a {
                        @include font-rem(400, 18, 30);
                        font-family: 'GothamProRegular', Arial, sans-serif;
                        display: block;
                        color: $color_a;
                        padding: 0 15px;
                        border-bottom: 1px solid #E8E8E8;
                        transition: all 0.2s ease-in-out;

                        &:hover {
                            text-decoration: none;
                            background-color: #E8E8E8;
                        }

                    }

                    &:first-child a {
                        border-top: 1px solid #E8E8E8;
                    }

                }

            }

        }

    }

}

.none-button {
    display: none !important;
}
