@font-face {
    font-family: 'Avenir-Book';
    src:local('Avenir-Book'),
    url('assets/fonts/Avenir-Book.woff') format('woff');
}

@font-face {
    font-family: "GothamProRegular";
    src: url("assets/fonts/GothamProRegular/GothamProRegular.eot");
    src: url("assets/fonts/GothamProRegular/GothamProRegular.eot?#iefix")format("embedded-opentype"),
    url("assets/fonts/GothamProRegular/GothamProRegular.woff") format("woff"),
    url("assets/fonts/GothamProRegular/GothamProRegular.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: "GothamProBold";
    src: url("assets/fonts/GothamProBold/GothamProBold.eot");
    src: url("assets/fonts/GothamProBold/GothamProBold.eot?#iefix")format("embedded-opentype"),
    url("assets/fonts/GothamProBold/GothamProBold.woff") format("woff"),
    url("assets/fonts/GothamProBold/GothamProBold.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}

* {
    @include placeholder();
}

body {
	background-color: $body-bg;
	color: $body-color;
	font-size: 100%;
    font-family: $base-font-family, Arial, sans-serif;
}
a {
	text-decoration: none;
	color: $a-color;

    &:hover {
        text-decoration: underline;
        color: $a-color-hover;
    }
}


.trend {
    padding-top: 25px;
    padding-bottom: 40px;
    background-color: $color_a;

    &__img {

        img {
            width: auto;
            max-width: 100%;
            height: auto;
        }

    }

    &__text {
        @include font-rem(400, 100, 100);
        padding-top: 160px;
        color: #fff;

        @include max-screen(1199px) {
            padding-top: 120px;
        }

        @include max-screen(991px) {
            @include font-rem(400, 70, 76);
            padding-top: 95px;
        }

        @include max-screen(767px) {
            @include font-rem(400, 40, 46);
            padding-top: 40px;
            text-align: center;
        }

    }

}

.info {
    padding-top: 70px;
    position: relative;

    &__text {
        width: 100%;
        max-width: 1000px;
        margin-left: auto;
        margin-right: auto;
        padding: 0 10px 100px;
        color: #393939;
        position: relative;

        &:before {
            content: '';
            position: absolute;
            bottom: -95px;
            left: 50%;
            transform: translateX(-50%);
            @extend %arrow;
            z-index: 99;
        }

        h1 {
            @include font-rem(700, 30, 40);
            font-family: 'GothamProBold', Arial, sans-serif;
            padding-bottom: 15px;

            @include max-screen(575px) {
                @include font-rem(400, 20, 30);
            }

        }

        p {
            @include font-rem(400, 20, 30);
            font-family: 'GothamProRegular', Arial, sans-serif;
            text-align: justify;
            padding-bottom: 10px;

            @include max-screen(575px) {
                @include font-rem(400, 16, 24);
            }

        }

    }

    &__file {
        width: 100%;
        background-color: #ebebeb;
        padding-top: 120px;
        padding-bottom: 50px;
        text-align: center;

        .file-download {
            display: inline-block;
            width: 300px;
            height: 300px;
            background-color: $color_a;
            border-radius: 50%;
            position: relative;
            transition: all 0.3s ease-in-out;

            &:before {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                @extend %file;
            }

            &:hover {
                transform: scale(0.9);
            }

            @include max-screen(991px) {
                width: 280px;
                height: 280px;
            }

        }


    }

}
