//////////////////////////////////////////////
// FOOTER
//////////////////////////////////////////////
.site-footer {
    background-color: #686868;
    padding-top: 20px;
    padding-bottom: 40px;
    color: #fff;
    @include font-rem(400, 16, 26);
    font-family: 'GothamProRegular', Arial, sans-serif;

    &__information {

        p {
            display: block;

            a {
                display: inline-block;
                color: #fff;
                text-decoration: none;
                transition: all 0.2s linear;

                &:hover {
                    text-decoration: none;
                    color: $color_a;
                }

            }
        }

        a.link {
            display: inline-block;
            color: #fff;
            text-decoration: underline;
            margin-top: 15px;

            &:hover {
                color: $color_a;
            }

        }

    }

    &__label {
        text-align: right;

        .iconic {
            display: inline-block;
            width: 200px;

            img {
                width: auto;
                max-width: 100%;
                height: auto;
            }

        }

        @include max-screen(575px) {
            text-align: center;
            padding-top: 20px;
        }

    }

    &__logo {
        display: inline-block;
        padding-bottom: 25px;

        img {
            width: auto;
            max-width: 100%;
            height: auto;
        }

    }

}
